<!-- 试题详情 线上开始未作答 -->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';
*{
	box-sizing: border-box;
}
.page{
    position: relative;
	width: 100%;
    height: 100%;
    padding-bottom: 80px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.9298) 30vh, rgba(255,255,255,0) 60vh);
}
.content{
	padding: 20px 20px 0;
    overflow: auto;
    height: 100%;
	.paperName {
		font-size: 20px;
		color: #FF4D4F;
		font-weight: bold;
		line-height: 30px;
		text-align: center;
	}

	.papeDate {
		font-size: 16px;
		font-weight: 400;
		color: #444444;
		line-height: 35px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.hint {
		width: 100%;
		height: 80px;
		background: #FFF8F1;
		border-radius: 14px;
		border: 1px solid $comOrange;
		padding: 0 26px;
		font-size: 28px;
		color: $comOrange;

		.iconimg {
			width: 40px;
			height: 40px;
			margin-right: 13px;
		}
	}
	.table {
		margin-top: 10px;
		border-radius: 8px;
		border: 1px solid #AAAAAA;
		width: 100%;

		.tr {
			width: 100%;

			.td {
				width: 100%;
				padding: 7px 16px;
				line-height: 24px;
				border: 1px solid #AAAAAA;
				border-right: none;
				border-bottom: none;
				font-weight: 400;

				&:first-child{
					border-left: none;
				}
				.title {
					font-size: 14px;
					font-weight: 400;
					color: #888888;
				}

				.val {
					flex: 1;
					font-size: 14px;
					color: #3D3D3D;
					margin-left: 6px;
				}
			}

			&:first-child {
				.td {
					border-top: none;
				}
			}
		}
	}
}
.flotageBtn{
	justify-content: center !important;
}
</style>

<template>
    <div class="page zwbtn">
		<div class="content">
			<div class="paperName">{{paperInfo.tepap_title}}</div>
			<div class="papeDate flex_end_align">
				<div class="dian" :class="paperInfo.stexa_type!=10?'':''"></div>
				<text class="c-3" v-if="paperInfo.stexa_type==10">线上考试</text> 
				<text class="c-orange" v-else>线下考试</text> 
			</div>
			<!-- 线下拍照提示 -->
			<div class="hint flex_content_center" v-if="paperInfo.stexa_type==20&&paperInfo.stexa_status<30">
				<img class="iconimg" src="@assets/images/date.png" alt="">
				温馨提示：本次属于线下考试，请拍照上传！
			</div>
			<!-- 考试数据 -->
			<div class="table">
				<div class="tr flex_align">
					<div class="td flex">
						<div class="title">考试科目:</div>
						<div class="val" v-if="paperInfo.sysub_name">{{paperInfo.sysub_name}}</div>
					</div>
					<div class="td flex">
						<div class="title">考试时间:</div>
						<div class="val" v-if="paperInfo.sysub_name">{{ paperInfo.stexa_starttime | formatTime("MM月DD日 HH:mm") }} ~ {{ paperInfo.stexa_endtime | formatTime("HH:mm") }}</div>
					</div>
					<div class="td flex">
						<div class="title">监考老师:</div>
						<div class="val" v-if="paperInfo.teuse_name">{{paperInfo.teuse_name}}</div>
					</div>
				</div>
				<div class="tr flex_align">
					<div class="td flex">
						<div class="title">满分:</div>
						<div class="val" v-if="paperInfo.tepap_sumscore">{{paperInfo.tepap_sumscore}}</div>
					</div>
					<div class="td flex">
						<div class="title">得分:</div>
						<div class="val c-orange" v-if="paperInfo.stexa_score">{{paperInfo.stexa_score}}分</div>
						<div class="val fs-36" v-else>—</div>
					</div>
					<div class="td flex">
						<div class="title">答题状态:</div>
						<div class="val c-green" v-if="paperInfo.stexa_finishstatus==10">未开始答题</div>
						<div class="val c-orange" v-if="paperInfo.stexa_finishstatus==20">答题进行中</div>
						<div class="val c-blue" v-if="paperInfo.stexa_finishstatus==30">答题结束</div>
					</div>
				</div>
				<div class="tr flex_align" v-if="paperInfo.tepap_rule">
					<div class="td flex">
						<div class="title">考试说明:
						</div><div class="val">{{paperInfo.tepap_rule}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flotageBtn bg-c-f" v-if="showbtntype>0">
			<div class="combtn1" v-if="showbtntype==10">考试未开始</div>
			<div class="combtn3" v-if="showbtntype==20" @click="goDetails()">开始答题</div>
			<div class="combtn8" v-if="showbtntype==30">考试结束</div>
		</div>
	</div>
</template>

<script>
import {stuexamination_info} from "@api/home"
export default {
    name: 'examinationFinish',
		components: {
		},
    data() {
        return {
			// 试卷id
			stexa_id:0,
			//试卷简介内容
			paperInfo:{},
			//当前按钮状态 10未开始 20进行中 30已结束
			showbtntype:0,
        }
    },
    created() {
        this.stexa_id=this.$route.params.id
        this.getData();
    },
    methods: {
       
        async getData() {
            let { data } = await stuexamination_info({stexa_id:this.stexa_id});
			this.paperInfo=data
			//已经考试完成
			if(data.stexa_finishstatus==30){
				this.showbtntype=30
			}else{
				//获取按钮显示
				this.setbtnshow(parseInt(data.stexa_starttime),parseInt(data.stexa_endtime))
			}
            this.$forceUpdate();
        },
		// 跳转详情
		goDetails(){
			this.$router.push({
				name:"EXAM_INATION_DETAILS",
				params: {
					id:this.stexa_id,
					endtime:this.paperInfo.stexa_endtime,
					make_type:this.paperInfo.stexa_make_type,
					paper_url:this.paperInfo.stexa_att_paper_url?this.paperInfo.stexa_att_paper_url:0,
				}
			})
		},
		//刷新按钮显示 
		setbtnshow(starttime,endtime){
			let nowTime;
			let intervals = setInterval(()=>{
				nowTime = (Date.parse(new Date()) / 1000);
				if (starttime < nowTime && nowTime <= endtime ) {
					// 进行中
					if(this.showbtntype!=20){
						this.showbtntype=20
					}
				}else if (starttime> nowTime) {
					//未开始
					this.showbtntype=10
				}else if (nowTime > endtime) {
					//结束
					this.showbtntype=30
					clearInterval(intervals);
				}
			}, 1000);
		},
      
    },
}
</script>